
import { defineComponent, onMounted, computed, ref } from "vue";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTListWidget5 from "@/components/widgets/lists/Widget5.vue";
import KTTableWidget5 from "@/components/widgets/tables/Widget5.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as JWT from '@/core/services/JwtService';
import Yup from "@/bundle/validations/YupExpended";
import { useForm, ErrorMessage, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import {AlertMessage} from "@/bundle/AlertMessage";

interface Permissionnaire
{
    raisonSociale: string;
    telephone: string;
    email: string;
    boitePostale: string;
}

export default defineComponent({
  name: "UpdateInformation",
  components: {
    KTChartWidget1,
    KTListWidget5,
    KTTableWidget5,
    KTListWidget1,
    ErrorMessage,
    Field

  },
  setup() {
    let isEditable = ref(false);
    let loading = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    /*
    * Permet de recuperer une entité spécifique
    */
    const permissionnaireAuth = computed(() => {
      return JWT.getAuthUser();
    });

    const toggleView = () => {
      isEditable.value = !isEditable.value;
    }

    const formData = ref<Permissionnaire>({
      raisonSociale : permissionnaireAuth.value.raisonSociale,
      telephone : permissionnaireAuth.value.telephone,
      email : permissionnaireAuth.value.email,
      boitePostale : permissionnaireAuth.value.boitePostale,
    });

    let permissionnaireSchema = Yup.object().shape({
      raisonSociale : Yup.string().required().min(2).max(60).label("La raison sociale"),
      telephone : Yup.string().required().min(2).max(60).label("Le téléphone"),
      email : Yup.string().required().email().label("L'adresse e-mail"),
      boitePostale : Yup.string().required().label("La boîte postale"),
    });

    const { resetForm, handleSubmit, errors, setErrors } = useForm<Permissionnaire>({
      validationSchema: permissionnaireSchema,
    });

    const submit = handleSubmit((values) => {
      loading.value = true;
      let data: any;
      data = formData.value;

      ApiService.put("/api/permissionnaire/"+permissionnaireAuth.value.id, data)
          .then( ({ data }) => 
            {
              loading.value = false;
                var title = "Modifications"
                var message = "<stong>Félicitation</strong>! Les modifications ont enregistrées!"
                AlertMessage.onSuccess(message, title).then(() => {
                });
            })
          .catch((error) => {
            loading.value = false;
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              return false;
          });
    });

    const getPermissionnaire= () => {
      ApiService.query('/api/permissionnaire/' + JWT.getAuthUser().id, {
        params: {
          size: 100000000
        }
      }).then((res: any) => {
        formData.value = res.data;
      }).catch(error => { 
        AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.");
      });
    }

    onMounted(() => {
      getPermissionnaire();
      
    });
    

    return { 
      toggleView,
      submit,
      permissionnaireAuth,
      isEditable,
      formData,
      loading
    }
  },
});
