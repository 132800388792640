
import { defineComponent, onMounted, computed, ref } from "vue";
import * as JWT from '@/core/services/JwtService';
import Yup from "@/bundle/validations/YupExpended";
import { useForm, ErrorMessage, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import {AlertMessage} from "@/bundle/AlertMessage";

interface Password
{
  id: any;
  oldPassword: string;
  newPassword: string;
}

export default defineComponent({
  name: "ResetPasswordForm",
  components: {
    ErrorMessage,
    Field
  },
  setup() {
    let loading = ref(false);

    /*
    * Permet de recuperer une entité spécifique
    */
    const permissionnaireAuth = computed(() => {
      return JWT.getAuthUser();
    });

    const formData = ref<Password>({
      id: permissionnaireAuth.value.id,
      oldPassword: '',
      newPassword: ''
    });

    let passwordSchema = Yup.object().shape({
      id: Yup.string().notRequired().label("L'id"),
      oldPassword : Yup.string().noWhiteSpace().min(8).required().label("Le mot de passe"),
      newPassword : Yup.string().noWhiteSpace().min(8).required().label("Le mot de passe"),
      repeatedPassword : Yup.string().oneOf([Yup.ref('newPassword'), null], 'Le mot de passe et la confirmation ne correspondent pas')
    });

    const { resetForm, handleSubmit, errors, setErrors } = useForm<Password>({
      validationSchema: passwordSchema,
    });

    const submit = handleSubmit((values) => {
      loading.value = true;
      let data: any;
      data = formData.value;

      ApiService.put("/api/permissionnaire/resetpwd", data)
          .then( ({ data }) => 
            {
              loading.value = false;
                var title = "Modifications"
                var message = "<stong>Félicitation</strong>! Les modifications ont enregistrées!"
                AlertMessage.onSuccess(message, title).then(() => {
                });
            })
          .catch((error) => {
            loading.value = false;
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              return false;
          });
    });

    onMounted(() => {
    });
    
    return { 
      submit,
      formData,
      loading,
      errors
    }
  },
});
